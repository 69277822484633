<template>
  <section>
    <ContentCard data-auto-id="realm-list" title="Realms">
      <template #extras>
        <Actions
          :actions="cardActions"
          @action="handleCardActions"
          auto-id="realm-card"
        />
      </template>
      <div class="pt-8 px-4 pb-4">
        <p>
          Realms are the main building blocks of identity management with Tozny.
          Create a realm then configure to allow easy registration and login all
          with end to end encryption by default.
        </p>
        <p class="mb-8">
          There is no limit to the number of realms you can have.
        </p>
        <div class="flex">
          <StatTile
            v-if="realms.length"
            class="mr-4"
            title="Total Realms"
            icon="landscape"
            :value="`${realms.length}`"
          />
          <StatTile
            v-if="totalUsersAcrossAllRealms"
            title="Users across all Realms"
            icon="people"
            :value="`${totalUsersAcrossAllRealms}`"
          />
        </div>
        <p class="font-display1" v-if="totalUsersAcrossAllRealms">
          <span class="font-bold"></span>
        </p>
        <ItemTable
          :clickRow="true"
          @rowClick="rowClick"
          data-auto-id="realm-table"
          :items="realms"
          :columns="tableColumns"
        >
          <template #realm="{ itemData }">
            <span class="text-tiny lg:text-base">{{ itemData }}</span>
          </template>
          <template #count="{ itemData }">
            <span class="text-tiny lg:text-base">{{ itemData }}</span>
          </template>
          <template #icon>
            <i class="material-icons micon text-gray-dark"
              >keyboard_arrow_right</i
            >
          </template>
        </ItemTable>
      </div>
    </ContentCard>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Actions from '../../../../Common/Actions'
import ContentCard from '../../../../Common/ContentCard'
import ItemTable from '../../../../Common/ItemTable'
import StatTile from './StatTile.vue'
export default {
  name: 'RealmList',
  components: {
    Actions,
    ContentCard,
    ItemTable,
    StatTile,
  },
  data: () => ({
    actions: [
      {
        event: 'manage',
        altText: 'manage realm',
        icon: 'input-outline',
      },
    ],
  }),
  props: {
    realms: {
      type: Array,
      default: () => [],
      validator(items) {
        for (let item of items) {
          if (typeof item.name !== 'string') {
            return false
          }
          if (!item.id) {
            return false
          }
        }
        return true
      },
    },
    errorMessage: String,
  },
  computed: {
    tableColumns() {
      return [
        {
          title: 'ID',
          target: 'id',
          slot: 'id',
        },
        {
          title: 'Realm Name',
          target: 'name',
          slot: 'name',
        },
        {
          title: 'Total Users',
          target: 'count',
          slot: 'count',
        },
        {
          title: '',
          target: '',
          slot: 'icon',
          className: ' w-px',
        },
      ]
    },
    cardActions() {
      return [
        {
          event: 'add',
          altText: 'create realm',
          icon: 'add-outline',
        },
      ]
    },
    ...mapGetters('realms', ['totalUsersAcrossAllRealms']),
  },
  methods: {
    handleAction(action) {
      this.$emit(action.event, action.realm)
    },
    handleCardActions(action) {
      this.$emit(action.event)
    },
    rowClick: function (item) {
      this.$emit('select', item.id)
    },
  },
}
</script>

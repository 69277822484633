<template>
  <MainLayout>
    <Banner
      v-if="showGlobalError"
      buttonText="Okay"
      type="red"
      :clickMethod="dismissGlobalError"
    >
      {{ errorMessage }}
    </Banner>
    <div
      v-if="status === 'loading'"
      class="w-full flex items-center justify-center"
    >
      <ToznyLoader class="text-tozny w-24 h-24" />
    </div>
    <NoRealms
      v-if="statusZero"
      :create-realm="addRealm"
      :loading="status === 'zero.adding'"
      :errorMessage="errorMessage"
    />
    <RealmList
      v-if="statusIdle"
      :realms="realms"
      @select="selectRealm"
      @add="transitionStatus('add')"
    />
    <AddRealm
      v-if="statusAdding"
      :create-realm="addRealm"
      :loading="status === 'adding'"
      :errorMessage="errorMessage"
      @cancel="transitionStatus('idle')"
    />
    <RealmDetail
      v-if="statusRealmDetail"
      :realm="selectedRealm"
      @backToRealmList="unselectRealm"
      @confirmDelete="confirmDelete"
      @setEmailRecovery="setEmailRecovery"
    />
    <RealmDeleteDialogue
      :showing="showDeleteDialogue"
      :realmToDelete="realmToDelete"
      @confirm="deleteSelectedRealm"
      @cancel="cancelDelete"
    />
  </MainLayout>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Banner from '@/Common/Banner'
import MainLayout from '@/Common/MainLayout/MainLayout'
import ToznyLoader from '../../../Common/ToznyLoader'
import NoRealms from './Components/NoRealms'
import RealmList from './Components/RealmList'
import AddRealm from './Components/AddRealm'
import RealmDetail from './Components/RealmDetail'
import RealmDeleteDialogue from './Components/RealmDeleteDialogue'

export default {
  name: 'Realms',
  components: {
    RealmList,
    Banner,
    MainLayout,
    NoRealms,
    ToznyLoader,
    AddRealm,
    RealmDetail,
    RealmDeleteDialogue,
  },
  computed: {
    ...mapState('realms', [
      'status',
      'realms',
      'errorMessage',
      'realmToDelete',
      'selectedRealmId',
      'createdRealm',
      'showingCreatedRealm',
    ]),
    ...mapGetters('realms', ['selectedRealm']),
    statusZero() {
      return this.status === 'zero' || this.status === 'zero.adding'
    },
    statusIdle() {
      return this.status === 'idle'
    },
    statusAdding() {
      return this.status === 'add' || this.status === 'adding'
    },
    showDeleteDialogue() {
      return this.status === 'realm.delete'
    },
    statusRealmDetail() {
      return this.status === 'realm.detail' || this.status === 'realm.delete'
    },
    showGlobalError() {
      return (
        (this.status === 'idle' && this.errorMessage) ||
        (this.status === 'realm.detail' && this.errorMessage) ||
        this.status === 'globalError'
      )
    },
  },
  methods: {
    ...mapActions('realms', [
      'initialize',
      'loadRealms',
      'addRealm',
      'confirmDelete',
      'cancelDelete',
      'deleteRealm',
      'transitionStatus',
      'selectRealm',
      'setEmailRecovery',
      'closeAddRealmDialogue',
      'dismissGlobalError',
    ]),
    deleteSelectedRealm() {
      this.deleteRealm(this.realmToDelete)
    },
    unselectRealm() {
      this.selectRealm(null)
      this.transitionStatus('idle')
    },
  },
  created: async function () {
    await this.initialize()
  },
}
</script>

<style></style>

<template>
  <div class="shadow rounded py-2 px-4">
    <span class="text-lil font-semibold font-display1 text-gray-dark">
      {{ title }}
    </span>
    <div class="flex items-center text-lg text-tozny">
      <i class="material-icons micon mr-2">{{ icon }}</i>
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatTile',
  props: {
    title: String,
    icon: String,
    value: String,
  },
}
</script>

<template>
  <ContentCard title="Advanced">
    <p class="px-4 py-8">
      Tozny is continuously working on new tools and services to provide, if you
      have a specific request you would like to see added, please send us an
      email at
      <a href="mailto:support@tozny.com" class="text-tozny">support@tozny.com</a
      >.
    </p>
  </ContentCard>
</template>

<script>
import ContentCard from '@/Common/ContentCard'

export default {
  name: 'AdvancedInfo',
  components: {
    ContentCard,
  },
}
</script>

<template>
  <section>
    <ContentCard data-auto-id="webhooks-list" title="Webhooks">
      <template #extras>
        <Actions
          :actions="cardActions"
          @action="handleCardActions"
          auto-id="webhooks-card"
        />
      </template>
      <div class="pt-8 px-4 pb-4">
        <p class="mb-8">
          We are currently sending POST requests to the following URL’s below
          with details of any subscribed events:
        </p>
        <ItemTable
          @rowClick="rowClick"
          :clickRow="true"
          data-auto-id="webhooks-table"
          :items="webhooks"
          :columns="tableColumns"
        >
          <template #status="{ itemData }">
            <StatusDot
              :status="itemData"
              :auto-id="`webhooks-status-${itemData}`"
            />
          </template>
          <template #token="{ itemData }">
            <span class="text-tiny lg:text-base">{{ itemData }}</span>
          </template>
          <template #icon>
            <i class="material-icons micon text-gray-dark"
              >keyboard_arrow_right</i
            >
          </template>
        </ItemTable>
      </div>
    </ContentCard>
  </section>
</template>

<script>
import Actions from '../../../Common/Actions'
import ContentCard from '../../../Common/ContentCard'
import ItemTable from '../../../Common/ItemTable'
import StatusDot from '@/Common/StatusDot'
export default {
  name: 'WebhooksList',
  components: {
    Actions,
    ContentCard,
    ItemTable,
    StatusDot,
  },
  props: {
    webhooks: {
      type: Array,
      default: () => [],
      validator(items) {
        for (let item of items) {
          if (typeof item.webhook_id !== 'number' || !item.webhook_id) {
            return false
          }
          if (typeof item.webhook_url !== 'string' || !item.webhook_url) {
            return false
          }
          if (typeof item.triggers !== 'object' || !item.triggers) {
            return false
          }
          if (typeof item.events !== 'number' || !item.events) {
            return false
          }
          if (typeof item.status !== 'boolean') {
            return false
          }
        }
        return true
      },
    },
    errorMessage: String,
  },
  computed: {
    tableColumns() {
      return [
        {
          title: 'Payload',
          target: 'webhook_url',
          slot: 'webhook_url',
        },
        {
          title: 'Webhook ID',
          target: 'webhook_id',
          slot: 'webhook_id',
        },
        {
          title: 'Events',
          target: 'events',
          slot: 'events',
        },
        {
          title: 'Status',
          target: 'status',
          slot: 'status',
        },
        {
          title: '',
          target: '',
          slot: 'icon',
          className: ' w-px',
        },
      ]
    },
    cardActions() {
      return [
        {
          event: 'add',
          altText: 'add webhook',
          icon: 'add-outline',
        },
      ]
    },
  },
  methods: {
    handleAction(action) {
      this.$emit(action.event, action.webhook)
    },
    handleCardActions(action) {
      this.$emit(action.event)
    },
    rowClick: function (item) {
      this.$emit('show', item.webhook_id)
    },
  },
}
</script>

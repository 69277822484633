<template>
  <DialogueBox
    data-auto-id="realm-delete-dialogue"
    :showing="showing"
    title="Confirm Deletion"
  >
    <div class="p-4">
      <p>
        Are you sure you want to remove this realm? All users and configuration
        will be lost. This action is not reversable.
      </p>
      <dl class="mb-8">
        <dt data-auto-id="realm-delete-payload-url" class="font-bold mb-2">
          {{ realmToDelete.name }}
        </dt>
        <dd data-auto-id="realm-delete-id" class="text-tiny text-gray-dark">
          <span class="text-gray=dark">Realm Id:</span>
          {{ realmToDelete.id }}
        </dd>
      </dl>
      <div class="flex items-center">
        <button
          id="realm-delete-confirm-button"
          data-auto-id="realm-delete-confirm-button"
          @click.prevent="$emit('confirm')"
          class="m-0 mr-4"
        >
          Confirm
        </button>
        <a
          href="#!"
          @click.prevent="$emit('cancel')"
          class="text-gray-darkest no-underline"
          >Cancel</a
        >
      </div>
    </div>
  </DialogueBox>
</template>

<script>
import DialogueBox from '../../../../Common/DialogueBox'
export default {
  name: 'RealmDeleteDialogue',
  components: {
    DialogueBox,
  },
  props: {
    showing: Boolean,
    realmToDelete: Object,
  },
}
</script>

<template>
  <i v-if="status" class="material-icons micon text-success">lens</i>
  <i v-else class="material-icons micon text-error">lens</i>
</template>

<script>
export default {
  name: 'StatusDot',
  props: {
    status: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<template>
  <div data-auto-id="webhook-view" class="pb-8">
    <div>
      <div @click="back" class="flex mb-2 cursor-pointer">
        <i class="material-icons text-gray-dark">keyboard_arrow_left</i>
        <h5 class="text-gray-dark">Webhooks</h5>
      </div>
      <ContentCard class="px-4 pt-4 mb-8 flex justify-between">
        <div>
          <h3>{{ webhook.webhook_url }}</h3>
          <p class="text-gray-dark">Webhook ID: {{ webhook.webhook_id }}</p>
        </div>
        <div class="flex">
          <p class="pb-0 pr-4">Enabled</p>
          <i class="material-icons micon text-success">lens</i>
        </div>
      </ContentCard>

      <ContentCard>
        <h5
          class="px-4 pt-4 pb-1 text-gray-medium border-b-2 border-gray-light"
        >
          Event Triggers
        </h5>
        <ul class="p-4">
          <li v-for="(event, index) in webhook.triggers" v-bind:key="index">
            <span class="text-gray-medium mr-6">{{ index + 1 }}</span>
            {{
              event.api_event
                .replace('_', ' ')
                .split(' ')
                .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1))
                .join(' ')
            }}
          </li>
        </ul>
      </ContentCard>
      <div class="flex flex-col items-center">
        <h4 class="pb-4">Webhook Enabled</h4>
        <p class="pb-6">Would you like to delete it?</p>
        <ToznyButton
          id="delete-webhook-button"
          @click.native="deleteWebhook"
          buttonText="Delete Webhook"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ContentCard from '@/Common/ContentCard'
import ToznyButton from '@/Common/ToznyButton'
export default {
  name: 'Webhook',
  components: {
    ContentCard,
    ToznyButton,
  },
  props: {
    webhook: Object,
  },
  data: function () {
    return {}
  },
  methods: {
    back: function () {
      this.$emit('backToWebhooksList')
    },
    deleteWebhook: function () {
      this.$emit('deleteWebhook', this.webhook.webhook_id)
    },
  },
}
</script>

<template>
  <MainLayout class="advanced">
    <AdvancedInfo />
    <NoWebhooks
      v-if="statusZero"
      :create-webhook="createInitialWebhook"
      :loading="status === 'zero.adding'"
      :errorMessage="errorMessage"
    />
    <div
      v-if="status === 'loading'"
      class="w-full flex items-center justify-center"
    >
      <ToznyLoader class="text-tozny w-24 h-24" />
    </div>
    <AddWebhook
      v-if="statusAdding"
      :create-webhook="createWebhook"
      :loading="status === 'adding' || status === 'zero.adding'"
      :errorMessage="errorMessage"
      @cancel="
        webhooks.length ? transitionStatus('idle') : transitionStatus('zero')
      "
    />
    <WebhooksList
      v-if="statusIdle"
      :webhooks="normalizedWebhooks"
      @show="showWebhook"
      @add="transitionStatus('add')"
    />
    <Webhook
      v-if="statusShow"
      :webhook="activeWebhook"
      @backToWebhooksList="transitionStatus('idle')"
      @deleteWebhook="beginDelete"
    />
    <WebhookDeleteDialogue
      :showing="showDeleteDialogue"
      :delete-queue="deleteQueue"
      @confirm="confirmDelete"
      @cancel="cancelDeleteQueue"
    />
  </MainLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AddWebhook from './Components/AddWebhook'
import AdvancedInfo from './Components/AdvancedInfo'
import MainLayout from '../../Common/MainLayout/MainLayout'
import NoWebhooks from './Components/NoWebhooks'
import ToznyLoader from '@/Common/ToznyLoader'
import Webhook from './Components/Webhook'
import WebhookDeleteDialogue from './Components/WebhookDeleteDialogue'
import WebhooksList from './Components/WebhooksList'
export default {
  name: 'Advanced',
  components: {
    AddWebhook,
    AdvancedInfo,
    MainLayout,
    NoWebhooks,
    ToznyLoader,
    Webhook,
    WebhookDeleteDialogue,
    WebhooksList,
  },
  computed: {
    ...mapState('webhooks', [
      'activeWebhook',
      'status',
      'webhooks',
      'errorMessage',
      'deleteQueue',
    ]),

    normalizedWebhooks: function () {
      return this.webhooks.map((hook) => {
        // Status groups whether any of the hook triggers are enabled.
        const enabled = hook.triggers.filter(
          (trigger) => trigger.enabled === true
        )
        hook.status = !!enabled.length
        // Events indicates how many triggers a webhook has.
        const eventCount = hook.triggers.length
        hook.events = eventCount
        return hook
      })
    },
    statusZero() {
      return this.status === 'zero'
    },
    statusIdle() {
      return this.status === 'idle'
    },
    statusAdding() {
      return (
        this.status === 'add' ||
        this.status === 'adding' ||
        this.status === 'zero.adding'
      )
    },
    statusShow() {
      return this.status === 'show' || this.status === 'show.delete'
    },
    showDeleteDialogue() {
      return this.status === 'show.delete'
    },
    showGlobalError() {
      return (
        (this.status === 'idle' && this.errorMessage) ||
        this.status === 'globalError'
      )
    },
  },
  methods: {
    ...mapActions('webhooks', [
      'initialize',
      'loadWebhooks',
      'createWebhook',
      'enqueueDelete',
      'cancelDeleteQueue',
      'deleteWebhook',
      'showWebhook',
      'transitionStatus',
      'setActiveWebhook',
    ]),
    async createInitialWebhook() {
      await this.transitionStatus('add')
    },
    beginDelete(webhookId) {
      const webhook = this.webhooks.find((h) => h.webhook_id === webhookId)
      return this.enqueueDelete(webhook)
    },
    confirmDelete() {
      return this.deleteWebhook(this.deleteQueue)
    },
    showWebhook(webhookId) {
      const filteredWebhooks = this.webhooks
        .slice()
        .filter((h) => h.webhook_id === webhookId)
      const webhook = filteredWebhooks.length ? filteredWebhooks[0] : ''
      if (webhook) {
        this.setActiveWebhook(webhook)
        this.transitionStatus('show')
      }
    },
  },
  created: async function () {
    await this.initialize()
  },
}
</script>

<template>
  <ToznyForm
    :on-submit="handleSubmit"
    :error="error"
    :loading="loading"
    class="px-4 pb-4"
  >
    <h5 class="pb-4">Add Webhook</h5>
    <ToznyInput
      v-model="payloadURL"
      data-auto-id="new-webhook-url"
      id="new-webhook-url"
      class="mb-4"
      label="Payload URL"
      placeholder="Must be https://"
    />
    <label for="event-triggers">Event Triggers:</label>
    <div id="event-triggers" class="flex pb-16 ml-4 font-display1 font-base">
      <label class="checkbox-label">
        Authorizer Added
        <input
          type="checkbox"
          id="authorizer_added_input"
          value="authorizer_added"
          v-model="eventTriggers"
        />
        <span class="checkbox-custom"></span>
      </label>
    </div>

    <div class="flex items-center">
      <ToznyButton
        :loading="loading"
        id="new-webhook-submit"
        data-auto-id="new-webhook-submit"
        class="mb-0 mr-4"
        buttonText="Add Webhook"
      />
      <a
        href="#!"
        @click.prevent="$emit('cancel')"
        class="text-gray-darkest no-underline mr-8"
        >Cancel</a
      >
    </div>
  </ToznyForm>
</template>

<script>
import ToznyForm from '../../../Common/ToznyForm'
import ToznyButton from '../../../Common/ToznyButton'
import ToznyInput from '../../../Common/ToznyInput'
export default {
  name: 'WebhookForm',
  components: {
    ToznyForm,
    ToznyButton,
    ToznyInput,
  },
  props: {
    loading: Boolean,
    error: String,
  },
  data: function () {
    return {
      payloadURL: '',
      eventTriggers: ['authorizer_added'],
    }
  },
  methods: {
    handleSubmit() {
      const webhook = {
        webhook_url: this.payloadURL,
        triggers: this.eventTriggers,
      }
      this.$emit('submit', webhook)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/tailwind-variables.scss';

.checkbox-label {
  position: absolute;
}

.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkbox-label .checkbox-custom {
  position: absolute;
  margin-left: 0.5rem;
  height: 24px;
  width: 24px;
  background-color: $colors-white;
  border-radius: 5px;
  border: 2px solid $colors-tozny;
}

.checkbox-label .checkbox-custom::after {
  position: absolute;
  content: '';
  left: 12px;
  top: 12px;
  height: 0px;
  width: 0px;
  border-radius: 5px;
  border: solid $colors-white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity: 1;
}

.checkbox-label input:checked ~ .checkbox-custom {
  background-color: $colors-tozny;
  border-radius: 5px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 2px solid $colors-white;
}

.checkbox-label input:checked ~ .checkbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 8px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid $colors-white;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}
</style>

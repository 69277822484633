<template>
  <DialogueBox
    data-auto-id="webhook-delete-dialogue"
    :showing="showing"
    title="Confirm Deletion"
  >
    <div class="p-4">
      <p>Are you sure you want to remove this webhook?</p>
      <dl class="mb-8">
        <dt data-auto-id="webhook-delete-payload-url" class="font-bold mb-2">
          {{ deleteQueue.webhook_url }}
        </dt>
        <dd data-auto-id="webhook-delete-id" class="text-tiny text-gray-dark">
          <span class="text-gray=dark">'Webhook ID:</span>
          {{ deleteQueue.webhook_id }}
        </dd>
      </dl>
      <div class="flex items-center">
        <button
          id="webhook-delete-confirm-button"
          data-auto-id="webhook-delete-confirm-button"
          @click.prevent="$emit('confirm')"
          class="m-0 mr-4"
        >
          Confirm
        </button>
        <a
          href="#!"
          @click.prevent="$emit('cancel')"
          class="text-gray-darkest no-underline"
          >Cancel</a
        >
      </div>
    </div>
  </DialogueBox>
</template>

<script>
import DialogueBox from '../../../Common/DialogueBox'
export default {
  name: 'WebhookDeleteDialogue',
  components: {
    DialogueBox,
  },
  props: {
    showing: Boolean,
    deleteQueue: Object,
  },
}
</script>

<template>
  <ContentCard data-auto-id="webhook-add" title="Add Webhook">
    <p class="mx-4 py-8 border-b-2 border-gray-light">
      We’ll send a POST request to the URL below with details of any subscribed
      events.
    </p>
    <WebhookForm
      @submit="createWebhook"
      @cancel="$emit('cancel')"
      :loading="loading"
      :error="errorMessage"
    />
  </ContentCard>
</template>

<script>
import ContentCard from '@/Common/ContentCard'
import WebhookForm from './WebhookForm'
export default {
  name: 'AddWebhooks',
  components: {
    ContentCard,
    WebhookForm,
  },
  props: {
    createWebhook: Function,
    loading: Boolean,
    errorMessage: String,
  },
}
</script>

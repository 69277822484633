<template>
  <section>
    <ContentCard data-auto-id="realm-add" title="Create Realm">
      <div class="py-8 px-4">
        <RealmForm
          @submit="createRealm"
          @cancel="$emit('cancel')"
          :loading="loading"
          :error="errorMessage"
        />
      </div>
    </ContentCard>
  </section>
</template>

<script>
import ContentCard from '@/Common/ContentCard'
import RealmForm from './RealmForm'
export default {
  name: 'AddRealms',
  components: {
    ContentCard,
    RealmForm,
  },
  props: {
    createRealm: Function,
    loading: Boolean,
    errorMessage: String,
  },
}
</script>

<template>
  <section>
    <ContentCard data-auto-id="webhooks-no-content" class="text-center">
      <div class="p-12">
        <img
          src="@/assets/webhooks.svg"
          alt="illustration of an empty office"
          class="inline-block mb-10"
        />
        <h3 class="text-sm mb-6 text-semibold">Create a Webhook</h3>
        <p class="mb-10">
          We’ll send a POST request to the URL below with details of any
          subscribed events.
        </p>
        <div class="flex justify-center">
          <ToznyButton
            data-auto-id="zero-state-create-webhook-button"
            id="zero-state-create-webhook-button"
            @click.native="createWebhook"
            buttonText="Create Webhook"
          />
        </div>
      </div>
    </ContentCard>
  </section>
</template>

<script>
import ContentCard from '../../../Common/ContentCard'
import ToznyButton from '@/Common/ToznyButton'
export default {
  name: 'NoWebhooks',
  components: {
    ContentCard,
    ToznyButton,
  },
  props: {
    createWebhook: Function,
    loading: Boolean,
    errorMessage: String,
  },
}
</script>

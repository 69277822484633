<template>
  <div data-auto-id="realm-view" class="pb-8">
    <div>
      <a
        class="flex no-underline text-gray-dark"
        href="#!"
        @click.prevent="back"
      >
        <i class="material-icons">keyboard_arrow_left</i>
        <p>All Realms</p>
      </a>
      <ContentCard class="px-4 pt-4 mb-8 flex justify-between">
        <div class="pb-4">
          <h3>{{ realm.name }}</h3>
          <p class="text-gray-dark">Realm ID: {{ realm.id }}</p>
          <p class="text-gray-dark">Realm Admin: {{ realm.sovereign.name }}</p>
          <p class="text-gray-dark">User Count: {{ realm.count }}</p>
          <div>
            <ToznySwitch
              id="switch"
              label="Email Recovery Enabled"
              name="switching"
              v-model="realm.isBrokerAuthorized"
              @input="toggleEmailButton"
            />
          </div>
          <a
            v-if="loginUrl !== ''"
            class="flex btn no-underline"
            target="_blank"
            :href="loginUrl"
            >Manage Realm</a
          >
        </div>
      </ContentCard>

      <ContentCard>
        <h4
          class="px-4 pt-4 pb-1 text-gray-medium border-b-2 border-gray-light"
        >
          Danger Zone
        </h4>
        <div class="p-4">
          <ToznyForm :on-submit="deleteRealm">
            <ToznyButton
              data-auto-id="new-realm-submit"
              class="m-0 bg-error"
              buttonText="Delete Realm"
              :loading="false"
            />
          </ToznyForm>
        </div>
      </ContentCard>
    </div>
  </div>
</template>

<script>
import ContentCard from '@/Common/ContentCard'
import ToznyButton from '@/Common/ToznyButton'
import ToznyForm from '@/Common/ToznyForm'
import ToznySwitch from '@/Common/ToznySwitch'

export default {
  name: 'Realm',
  components: {
    ContentCard,
    ToznyButton,
    ToznyForm,
    ToznySwitch,
  },
  props: {
    realm: Object,
  },
  data: function () {
    return {
      loginUrl: '',
    }
  },
  methods: {
    back: function () {
      this.$emit('backToRealmList')
    },
    toggleEmailButton() {
      // v-model="realm.isBrokerAuthorized" already sets the bool flag for email recovery
      // pass this to parent for processing
      this.$emit('setEmailRecovery', this.realm)
    },
    deleteRealm: function () {
      this.$emit('confirmDelete', this.realm)
    },
    goToRealm() {
      window.open(this.realm.adminURL)
    },
  },
  async created() {
    this.loginUrl = await this.realm.getLoginUrl()
  },
}
</script>

<style scoped>
.btn {
  display: inline-block;
  margin-bottom: 0;
}
</style>
